// extracted by mini-css-extract-plugin
export var container = "blogTemplate-module--container--3qhss";
export var backLink = "blogTemplate-module--backLink--13Zji";
export var header = "blogTemplate-module--header--3niRw";
export var left = "blogTemplate-module--left--1tnlP";
export var image = "blogTemplate-module--image--1VEXD";
export var right = "blogTemplate-module--right--2eFMD";
export var creationDate = "blogTemplate-module--creationDate--2r0y_";
export var title = "blogTemplate-module--title--8K_SA";
export var divider = "blogTemplate-module--divider--TqEv0";
export var content = "blogTemplate-module--content--1iP3L";