import React from 'react'

import Img from 'gatsby-image'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import * as styles from './blogTemplate.module.scss'

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data

  const { frontmatter, html } = markdownRemark

  const headerImage = frontmatter.headerImage.childImageSharp.fixed

  return (
    <div className={styles.container}>
      <Helmet>
        <html lang="en" /> <title>HackConf News - {frontmatter.title}</title>
        <meta name="description" content={frontmatter.description} />
        <meta property="og:image" content={headerImage} />
      </Helmet>

      <a className={styles.backLink} href="/">
        Back to HackConf
      </a>
      <div className={styles.header}>
        <div className={styles.left}>
          <Img
            height="139"
            width="210"
            fixed={headerImage}
            className={styles.image}
          />
        </div>
        <div className={styles.right}>
          <h2 className={styles.creationDate}>{frontmatter.date}</h2>
          <h1 className={styles.title}>{frontmatter.title}</h1>
        </div>
      </div>
      <div className={styles.divider} />
      <div
        className={styles.content}
        dangerouslySetInnerHTML={{ __html: html }}
      />
    </div>
  )
}
export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date
        path
        title
        description
        headerImage {
          childImageSharp {
            fixed(width: 210, height: 139) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`
